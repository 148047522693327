import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import dayjs from "dayjs";
import {
  fetchWithdrawals,
  updateWithdrawal,
  updateMultipleWithdrawal,
  exportWithdrawals,
} from "@/store/Transactions/withdrawalsAction";
import { disableUser } from "@/store/user/actions";
import actionTypes from "@/store/Transactions/actionTypes";
import userActionTypes from "@/store/user/actionTypes";
import { getActionLoadingState } from "@/store/selectors";
import { currency, hasPermission } from "@/utils";
import RightPanel from "@/components/RightPanel";
import Dropdown from "@/components/DropdownMenu";
import Emptystate from "@/components/Emptystate";
import ConfirmationBox from "@/components/ConfirmationBox";
import Pagination from "@/components/Pagination";
import SearchBox from "@/components/SearchBox";
import ExportDropdown from "@/components/ExportDropdown";
import MultipleApprovalDropdown from "@/components/MultipleApprovalDropdown";

class Withdrawals extends Component {
  state = {
    open: false,
    previewMode: false,
    withdrawals: [],
    searchTerm: "",
    shouldSearch: false,
    selectedRowsIds: [],
  };

  canConfirm = hasPermission(this.props.permissions, "confirm_withdrawal");
  canApprove = hasPermission(this.props.permissions, "approve_withdrawal");
  canHold = hasPermission(this.props.permissions, "hold_withdrawal");
  canDisableUser = hasPermission(this.props.permissions, "disable_user");

  componentDidMount() {
    const { status = "pending" } = this.props.match.params;
    this.getWithdrawals(1, 10, status);
  }

  componentDidUpdate(prevProps, prevState) {
    const { status } = this.props.match.params;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ selectedRowsIds: [] }, () =>
        this.getWithdrawals(1, 10, status)
      );
    }
    if (
      this.state.searchTerm !== prevState.searchTerm &&
      this.state.shouldSearch
    ) {
      this.getWithdrawals(1, 10, status, this.state.searchTerm);
    }
  }

  getWithdrawals = (page, limit, status, searchTerm) => {
    this.props
      .fetchWithdrawals(page, limit, status, searchTerm)
      .then((withdrawals) => {
        this.setPageData(withdrawals, searchTerm);
      });
  };

  setPageData = (
    { lastPage, perPage, page, total, data: withdrawals },
    searchTerm
  ) => {
    this.setState({
      withdrawals,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
      shouldSearch: false,
    });
  };

  dropdownmenus = (withdrawalId, userId) => {
    const { status } = this.props.match.params;
    return [
      status === "pending" &&
        this.canConfirm && {
          name: "Confirm withdrawal",
          handler: () =>
            this.setConfirmationContext("confirmWithdrawal", withdrawalId),
        },
      status === "confirmed" &&
        this.canApprove && {
          name: "Approve withdrawal",
          handler: () =>
            this.setConfirmationContext("approveWithdrawal", withdrawalId),
        },
      ["pending", "confirmed"].includes(status) &&
        this.canHold && {
          name: "Hold withdrawal",
          handler: () =>
            this.setConfirmationContext("holdWithdrawal", withdrawalId),
        },
      status === "held" && {
        name: "Release withdrawal",
        handler: () =>
          this.setConfirmationContext("releaseWithdrawal", withdrawalId),
      },
      this.canDisableUser && {
        name: "Disable user",
        handler: () => this.setConfirmationContext("disableUser", null, userId),
        textClass: "text-red",
      },
      status === "active" && {
        name: "Reverse withdrawal",
        handler: () =>
          this.setConfirmationContext("reverseWithdrawal", withdrawalId),
      },
    ].filter(Boolean);
  };

  onRowClick = (e, withdrawal) => {
    if (!["BUTTON", "IMG", "LI", "INPUT"].includes(e.target.nodeName)) {
      this.setState({
        previewMode: true,
        previewItem: {
          "User ID": withdrawal.user?.id,
          "Full Name": `${withdrawal.user?.firstName} ${withdrawal.user?.lastName}`,
          Amount: withdrawal.amount,
          "Bank Code": withdrawal.user?.bankInfo?.bankCode,
          "Account Number": withdrawal.user?.bankInfo?.accountNumber,
          "Date & Time": dayjs(withdrawal.created_at).format(
            "D MMM YYYY hh:m:s"
          ),
        },
        open: true,
      });
    }
  };

  disableUser = (reason) => {
    this.props.disableUser(this.state.userId, reason, "disabled").then(() => {
      this.setState({ confirmationSuccess: true });
    });
  };

  updateWithdrawal = (newStatus, reason) => {
    const { withdrawals, withdrawalId, total } = this.state;
    this.props.updateWithdrawal(withdrawalId, newStatus, reason).then(() => {
      if (newStatus !== "initiate-reverse") {
        const allwithdrawals = [...withdrawals];
        const withdrawalIndex = allwithdrawals.findIndex(
          (withdrawal) => withdrawal.id === withdrawalId
        );
        allwithdrawals.splice(withdrawalIndex, 1);
        this.setState({
          withdrawals: allwithdrawals,
          confirmationSuccess: true,
          total: total - 1,
        });
      } else {
        this.setState({ confirmationContext: "" });
      }
    });
  };

  updateMultiple = (newStatus) => {
    const { selectedRowsIds, withdrawals, total } = this.state;
    this.props.updateMultipleWithdrawal(selectedRowsIds, newStatus).then(() => {
      const unchangedItems = withdrawals.filter(
        (data) => !selectedRowsIds.includes(data.id)
      );
      this.setState({
        withdrawals: unchangedItems,
        confirmationSuccess: true,
        total: total - selectedRowsIds.length,
        selectedRowsIds: [],
      });
    });
  };

  confirmationData = () => {
    const { isMultipleApproval, selectedRowsIds } = this.state;
    const addS = isMultipleApproval && selectedRowsIds.length > 1;
    return {
      confirmWithdrawal: {
        title: `Confirm withdrawal${addS ? "s" : ""}`,
        question: `Are you sure you want to confirm ${
          addS ? "these" : "this"
        } withdrawal${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("confirmed")
            : this.updateWithdrawal("confirmed"),
      },
      approveWithdrawal: {
        title: `Approve withdrawal${addS ? "s" : ""}`,
        question: `Are you sure you want to approve ${
          addS ? "these" : "this"
        } withdrawal${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("active")
            : this.updateWithdrawal("active"),
      },
      holdWithdrawal: {
        title: `Hold withdrawal${addS ? "s" : ""}`,
        question: `Are you sure you want to hold ${
          addS ? "these" : "this"
        } withdrawal${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("held")
            : this.updateWithdrawal("held"),
      },
      releaseWithdrawal: {
        title: `Release withdrawal${addS ? "s" : ""}`,
        question: `Are you sure you want to release ${
          addS ? "these" : "this"
        } withdrawal${addS ? "s" : ""}?`,
        action: () =>
          isMultipleApproval
            ? this.updateMultiple("pending")
            : this.updateWithdrawal("pending"),
      },
      disableUser: {
        title: "Reason for disabling user",
        requiresReason: true,
        action: (reason) => this.disableUser(reason),
      },
      reverseWithdrawal: {
        title: "Reason for reversing withdrawal",
        requiresReason: true,
        action: (reason) => this.updateWithdrawal("initiate-reverse", reason),
      },
    };
  };

  setConfirmationContext = (
    context,
    withdrawalId,
    userId,
    isMultipleApproval
  ) => {
    this.setState({
      confirmationContext: context,
      confirmationSuccess: false,
      withdrawalId,
      userId,
      isMultipleApproval,
    });
  };

  onCheckRow = (e, rowId) => {
    const selectedRows = [...this.state.selectedRowsIds];
    if (e.target.checked) {
      selectedRows.push(rowId);
    } else {
      const uncheckedRowIndex = selectedRows.findIndex((id) => id === rowId);
      selectedRows.splice(uncheckedRowIndex, 1);
    }
    this.setState({ selectedRowsIds: selectedRows });
  };

  renderWithdrawals = () => {
    const { status } = this.props.match.params;
    const { selectedRowsIds, withdrawals } = this.state;
    const allSelected =
        withdrawals.length > 0 && selectedRowsIds.length === withdrawals.length;

    return (
        <table className="custum-table">
          <tbody>
          <tr>
            {status !== "active" && (
                <th>
                  <input
                      type="checkbox"
                      checked={allSelected}
                      onChange={this.onCheckAllRows}
                  />
                </th>
            )}
            <th>User ID</th>
            <th style={{ width: "30rem" }}>Transaction Reference</th>
            <th>Full Name</th>
            <th>Amount</th>
            <th>Bank Name</th>
            <th>Account Number</th>
            <th>Date & Time</th>
            <th></th>
          </tr>
          {withdrawals.map((withdrawal) => (
              <tr
                  key={withdrawal?.id}
                  onClick={(e) => this.onRowClick(e, withdrawal)}
                  className="cursor-pointer"
              >
                {status !== "active" && (
                    <td className="row-checkbox">
                      <input
                          type="checkbox"
                          checked={selectedRowsIds.includes(withdrawal.id)}
                          onChange={(e) => this.onCheckRow(e, withdrawal.id)}
                      />
                    </td>
                )}
                <td>{withdrawal.user?.id}</td>
                <td style={{ width: "30rem" }}>
                  {withdrawal?.transaction?.reference}
                </td>
                <td className="text-capitalize">{`${withdrawal.user?.firstName} ${withdrawal?.user?.lastName}`}</td>
                <td className="font-heading font-medium">{`₦${currency(
                    withdrawal?.amount
                )}`}</td>
                <td>{withdrawal?.user?.bankInfo?.bankName}</td>
                <td>{withdrawal?.user?.bankInfo?.accountNumber}</td>
                <td>
                  {dayjs(withdrawal?.created_at).format("D MMM YYYY hh:m:s")}
                </td>
                <td className="custum-table__ellipsis">
                  <Dropdown
                      menu={this.dropdownmenus(
                          withdrawal?.id,
                          withdrawal?.user?.id
                      )}
                      arrow={true}
                  >
                    <button className="wrapper-button ellipsis">
                      <img
                          src={require("@/assets/icons/flat-ellipsis.svg").default}
                          alt="dropdown"
                      />
                    </button>
                  </Dropdown>
                </td>
              </tr>
          ))}
          </tbody>
        </table>
    );
  };

  onCheckAllRows = (e) => {
    const { withdrawals } = this.state;
    if (e.target.checked) {
      const allIds = withdrawals.map((withdrawal) => withdrawal.id);
      this.setState({ selectedRowsIds: allIds });
    } else {
      this.setState({ selectedRowsIds: [] });
    }
  };



  renderPreview = () => {
    const { previewItem } = this.state;
    return Object.keys(previewItem).map((field) => (
      <div className="panel-info__row" key={field}>
        <span className="panel-info__field">{field}</span>
        <span className="panel-info__value">{previewItem[field]}</span>
      </div>
    ));
  };

  closePanel = (e) => {
    e.preventDefault();
    this.setState({
      open: false,
      previewMode: false,
    });
  };

  render() {
    const {
      match: {
        params: { status = "pending" },
      },
      pageRequest,
      disableUserLoading,
    } = this.props;
    const {
      previewMode,
      withdrawals,
      confirmationContext,
      confirmationSuccess,
      lastPage,
      perPage,
      page,
      total,
      searchTerm,
    } = this.state;
    const prompt = this.confirmationData()[confirmationContext];
    const showMultiAprrovalDropdown = this.state.selectedRowsIds.length > 0;
    const multiApprovalOptions = [
      status === "pending" &&
        this.canConfirm && {
          name: "Confirm",
          handler: () =>
            this.setConfirmationContext("confirmWithdrawal", null, null, true),
        },
      status === "confirmed" &&
        this.canApprove && {
          name: "Approve",
          handler: () =>
            this.setConfirmationContext("approveWithdrawal", null, null, true),
        },
      ["pending", "confirmed"].includes(status) &&
        this.canHold && {
          name: "Hold",
          handler: () =>
            this.setConfirmationContext("holdWithdrawal", null, null, true),
        },
      status === "held" && {
        name: "Release",
        handler: () =>
          this.setConfirmationContext("releaseWithdrawal", null, null, true),
      },
    ].filter(Boolean);

    return (
      <div className="setup-inner">
        <RightPanel open={this.state.open} onClose={this.closePanel}>
          <h1 className="panel-heading">Withdralwal Details</h1>
          {previewMode && (
            <div className="panel-info">
              {this.renderPreview()}
              <button className="long-button" onClick={this.closePanel}>
                Close
              </button>
            </div>
          )}
        </RightPanel>
        <ConfirmationBox
          open={confirmationContext}
          closeHandler={() => this.setState({ confirmationContext: "" })}
          success={confirmationSuccess}
          title={prompt?.title}
          question={prompt?.question}
          action={prompt?.action}
          loading={pageRequest || disableUserLoading}
          requiresReason={prompt?.requiresReason}
        />
        <div className="setup-inner__top">
          <ul className="setup-inner__nav">
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "pending",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "pending"}
                activeClassName="setup-nav__item--active"
              >
                Pending Withdrawals
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "confirmed",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "confirmed"}
                activeClassName="setup-nav__item--active"
              >
                Pending Approval
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{
                  pathname: "active",
                  state: { pageTitle: "Transactions" },
                }}
                isActive={() => status === "active"}
                activeClassName="setup-nav__item--active"
              >
                Approved Withdrawals
              </NavLink>
            </li>
            <li className="setup-nav__item">
              <NavLink
                to={{ pathname: "held", state: { pageTitle: "Transactions" } }}
                isActive={() => status === "held"}
                activeClassName="setup-nav__item--active"
              >
                Held Withdrawals
              </NavLink>
            </li>
          </ul>
          <SearchBox
            placeholder="Search"
            handleSearch={(searchTerm) =>
              this.setState({ searchTerm, shouldSearch: true })
            }
            isActiveSearch={Boolean(searchTerm)}
            key={status}
          />
          {withdrawals.length > 0 && !showMultiAprrovalDropdown && (
            <ExportDropdown
              module="withdrawals"
              status={status === "active" ? "approved" : status}
              page={page}
              limit={perPage}
              downloadHandler={this.props.exportWithdrawals}
              hasMoreThanOnePage={lastPage > 1}
            />
          )}
          {showMultiAprrovalDropdown && (
            <MultipleApprovalDropdown options={multiApprovalOptions} />
          )}
        </div>
        {this.props.fetching ? (
          <div className="text-center text-primary">
            <div className="spinner-border" role="status"></div>
          </div>
        ) : (
          <div className="position-relative">
            {!withdrawals.length ? (
              <Emptystate
                title={`${searchTerm ? "No Result Found" : "No Withdralwals"}`}
                icon={require("@/assets/icons/info.svg").default}
              />
            ) : (
              <>
                <Pagination
                  totalPages={lastPage}
                  page={page}
                  limit={perPage}
                  changePageHandler={(page, limit) =>
                    this.getWithdrawals(page, limit, status, searchTerm)
                  }
                />
                <div className="table-overflow">
                  <div className="setup-inner__main setup-inner__expand">
                    {this.renderWithdrawals()}
                  </div>
                  <div className="data-count">
                    Showing
                    <span className="font-weight-bold mx-2">{`${withdrawals.length} of ${total}`}</span>
                    Withdrawals
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fetching: getActionLoadingState(state, actionTypes.GET_WITHDRAWALS_REQUEST),
    pageRequest: getActionLoadingState(
      state,
      actionTypes.WITHDRAWALS_PAGE_REQUEST
    ),
    disableUserLoading: getActionLoadingState(
      state,
      userActionTypes.DISABLE_USER_REQUEST
    ),
    permissions: state.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchWithdrawals: (page, limit, status, searchTerm) =>
      dispatch(fetchWithdrawals(page, limit, status, searchTerm)),
    updateWithdrawal: (id, status, reason = "") =>
      dispatch(updateWithdrawal(id, status, reason)),
    updateMultipleWithdrawal: (ids, status) =>
      dispatch(updateMultipleWithdrawal(ids, status)),
    disableUser: (userId, reason, status) =>
      dispatch(disableUser(userId, reason, status)),
    exportWithdrawals: (module, format, status, page, limit) =>
      dispatch(exportWithdrawals(module, format, status, page, limit)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Withdrawals)
);
